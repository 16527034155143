<template>
  <div class="content">
    <div class="row">
     <div class="usage-consideration">
          <h3>Usage Consideration</h3>
          <p class="mbottom-15">
            iServiceLogo -
          </p>
        </div>
      <div class="col-md-12">
        <div class="box box-default color-palette-box">
          <div class="box-header with-border">
            <h3 class="box-title"> iSerive Logos & Icons</h3>
          </div>
          <div class="box-body">
            <div class="row logos">
              <div class="col-md-12">
                <h3>Icons</h3>
                <div class="col-lg-6 center">
                  <img src="../assets/img/logos/iServiceLogos/IOnly/BlueGradient.png" height="60px;">
                  <br> <br>
                </div>
                <div class="col-lg-6 center">
                  <img src="../assets/img/logos/iServiceLogos/IOnly/BlueSolid.png" height="60px;">
                  <br> <br>
                </div>
                <div class="col-lg-6 center">
                  <img src="../assets/img/logos/iServiceLogos/IOnly/BlueSwoosh.png" height="60px;">
                  <br> <br>
                </div>
              </div>
              <div class="col-md-12">
                <h3>iServiceLogo</h3>
                <div class="col-lg-6 center">
                  <img src="../assets/img/logos/newlogo/1to1newlogo.png" height="60px;">
                  <br> <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogoComponents'
}
</script>
