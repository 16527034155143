<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
      <div class="usage-consideration">
          <h3>Usage Consideration</h3>
          <p class="mbottom-15">
            The following color schemes has been used through out the iservice.
          </p>
        </div>
        <div class="box box-default color-palette-box">
          <div class="box-header with-border">
            <h3 class="box-title">Color Palette</h3>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Primary</h4>
                <div class="color-palette-set">
                  <div class="bg-light-blue disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-light-blue color-palette"><span>#3c8dbc</span></div>
                  <div class="bg-light-blue-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Info</h4>
                <div class="color-palette-set">
                  <div class="bg-aqua disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-aqua color-palette"><span>#00c0ef</span></div>
                  <div class="bg-aqua-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Success</h4>
                <div class="color-palette-set">
                  <div class="bg-green disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-green color-palette"><span>#00a65a</span></div>
                  <div class="bg-green-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Warning</h4>
                <div class="color-palette-set">
                  <div class="bg-yellow disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-yellow color-palette"><span>#f39c12</span></div>
                  <div class="bg-yellow-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Danger</h4>
                <div class="color-palette-set">
                  <div class="bg-red disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-red color-palette"><span>#f56954</span></div>
                  <div class="bg-red-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Gray</h4>
                <div class="color-palette-set">
                  <div class="bg-gray disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-gray color-palette"><span>#d2d6de</span></div>
                  <div class="bg-gray-active color-palette"><span>Active</span></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Teal</h4>
                <div class="color-palette-set">
                  <div class="bg-teal disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-teal color-palette"><span>#39CCCC</span></div>
                  <div class="bg-teal-active color-palette"><span>Active</span></div>
                </div>
              </div>
              <div class="col-sm-4 col-md-2">
                <h4 class="text-center">Black</h4>
                <div class="color-palette-set">
                  <div class="bg-black disabled color-palette"><span>Disabled</span></div>
                  <div class="bg-black color-palette"><span>#111111</span></div>
                  <div class="bg-black-active color-palette"><span>Active</span></div>
                </div>
              </div>
            </div>
            <div class="">
              <h2 class="action-list-title">Example</h2>
              <p class="action-description">
                iService uses above color schemes buttons, labels, text colors and backgrounds.
              </p>
              <ul class="item-actions">
                <li>
                  <div>
                    <small class="label label-with-icon bg-blue">bg blue</small>
                    <div class="code-container">
                      <p>&lt;small class=&quot;label label-with-icon bg-blue&quot;&gt;bg blue&lt;/small&gt;</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                   <small class="bg-green label">bg green</small>
                   <div class="code-container">
                     <p>&lt;small class=&quot;bg-green label&quot;&gt;bg green&lt;/small&gt;</p>
                   </div>
                  </div>
                </li>
                <li>
                  <div>
                    <small class="bg-yellow label">bg yellow</small>
                    <div class="code-container">
                      <p>&lt;small class=&quot;bg-yellow label&quot;&gt;bg green&lt;/small&gt;</p>
                    </div>
                  </div>
                  </li>
                <li>
                  <div>
                    <small class="bg-red label">bg red</small>
                    <div class="code-container">
                      <p>&lt;small class=&quot;bg-red label&quot;&gt;bg green&lt;/small&gt;</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <small class="bg-gray label">bg gray</small>
                    <div class="code-container">
                      <p>&lt;small class=&quot;bg-gray label&quot;&gt;bg green&lt;/small&gt;</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <small class="bg-teal label">bg teal</small>
                    <div class="code-container">
                      <p>&lt;small class=&quot;bg-teal label&quot;&gt;bg teal&lt;/small&gt;</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ColorsComponents'
}
</script>
